<template>
  <LiefengContent :isBack="true"  @backClick="backClick">
    <template v-slot:title>预约记录
      <span class="eye" @click.stop="changeEye">
        <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
      </span>
    </template>
    <template v-slot:toolsbarRight>
      <Form :label-colon="true" :inline="true">
            <FormItem>
              <Input
                :maxlength="20"
                enter-button
                v-model.trim="searchData.title"
                placeholder="请输入商品名称"
                style="margin-right:10px;width: 150px"
              />
            </FormItem>
            <FormItem>
              <Select transfer style="margin-right:10px;width:150px" v-model="searchData.orderStatus">
                <Option :value="item.value" v-for="(item, index) in groupType" :key="index">{{ item.label }}</Option>
              </Select>
            </FormItem>
            <FormItem>
              <Input
                :maxlength="20"
                enter-button
                v-model.trim="searchData.username"
                placeholder="请输入填报人名称"
                style="margin-right:10px;width: 150px"
              />
            </FormItem>
            <FormItem>
              <Input
                :maxlength="20"
                enter-button
                v-model.trim="searchData.mobile"
                placeholder="请输入填报人手机号"
                style="margin-right:10px;width: 150px"
              />
            </FormItem>
             <Button style="margin-right:10px" type="primary" icon="ios-search" @click="seatchBtn()">搜索</Button>
              <Button style="margin-right:10px" type="success" icon="ios-refresh" @click="rest">重置</Button>
      </Form>
      <!-- <Button type="primary" icon="ios-search" @click="seatchBtn()">搜索</Button>
      <Button type="success" icon="ios-refresh" @click="rest">重置</Button> -->
      <!-- <Button type="error" @click="$router.push(`/shopindex?menuId=${$route.query.menuId}`)">返回</Button> -->
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        height="200"
        :loading="loading"
        :fixTable="true"
        :curPage="page"
        :total="total"
        :page-size="pageSize"
        :pagesizeOpts="[20, 30, 50, 100]"
        @hadlePageSize="hadlePageSize"
      ></LiefengTable>
      <!--订单详情-->
        <LiefengModal
        title="订单详情"
        width="700px"
        height="700px"
        :fullscreen="false"
        :value="analysisStatus"
        @input="analysisStatusFn"
        class="analysis"
      >
        <template v-slot:contentarea>
            <Detail @getDetail="getDetail" :showNum="showNum" :recordId="recordId" :informationId="informationId" :qrCode="qrCode"></Detail>
        </template>
        <template v-slot:toolsbar>
          <Button
            @click="analysisStatusFn(false)"
            type="info"
            >关闭</Button
          >
        </template>
      </LiefengModal>
      <!--取消订单原因-->
        <LiefengModal
        title="取消订单原因"
        width="300px"
        :fullscreen="false"
        :value="reasonStatus"
        @input="reasonStatusFn"
        class="analysis"
      >
        <template v-slot:contentarea>
            <div>
                <Input v-model="reason" placeholder="请输入取消订单原因" />
            </div>
           
        </template>
        <template v-slot:toolsbar>
          <Button
            @click="reasonStatusFn(false)"
            type="info"
            style="margin-right:10px"
            >取消</Button
          >
          <Button
            @click="saveReason"
            type="info"
            >确定</Button
          >
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/groupindex")
import LiefengContent from "@/components/LiefengContent3";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import Detail from './childrens/detail.vue'
export default {
  components:{LiefengContent,LiefengModal,LiefengTable,Detail},
  data(){
      return{
        hasSensitive:true,
          talbeColumns:[
              {
                    title: "订单编码",
                    align: "center",
                    minWidth: 180,
                    key: "recordId",
            },
              {
                    title: "订单状态",
                    align: "center",
                    minWidth: 180,
                    key: "orderStatus",
                     render:(h,params) =>{
                        return h('div',{},params.row.orderStatus == 1 ? '待支付':params.row.orderStatus == 2 ? '待领取':params.row.orderStatus == 3 ? '待归还' : params.row.orderStatus == 4 ? '待支付租金' : params.row.orderStatus == 5 ? '待评价': params.row.orderStatus == 6 ? '已完成' :params.row.orderStatus == 7?'已取消':'')
          }
            },
              {
                    title: "状态变更时间",
                    align: "center",
                    minWidth: 180,
                    key: "gmtModified",
                    render:(h,params) =>{
                        return h('div',{},params.row.gmtModified ? this.$core.formatDate(new Date(params.row.gmtModified),"yyyy-MM-dd hh:mm:ss"):'') 
                    }
            },
              {
                    title: "租赁商品名称",
                    align: "center",
                    minWidth: 180,
                    key: "title",
            },
              {
                    title: "租赁商品编码",
                    align: "center",
                    minWidth: 180,
                    key: "goodsNo",
            },
              {
                    title: "租赁商品分类",
                    align: "center",
                    minWidth: 180,
                    key: "subTypeName",
            },
              {
                    title: "填报人姓名",
                    align: "center",
                    minWidth: 180,
                    key: "username",
            },
              {
                    title: "填报人手机号",
                    align: "center",
                    minWidth: 180,
                    key: "mobile",
            },
              {
              fixed: "right",
              minWidth: 300,
              title: "操作",
              align: "center",
              render: (h, params) => {
                return h('div',
                        {
                            style: {
                            textAlign: "center",
                            display:'flex',
                            justifyContent:'center'
                            },
                        },[
                    
                  h('Button',{
                    props: {
                      type: "info",
                      size: "small",
                    },
                    style: {
                      marginRight: '10px'
                    },
                    on: {
                      click: () => {
                          ++this.showNum
                          this.recordId = params.row.recordId
                          this.informationId = params.row.informationId
                          this.orderStatus = params.row.orderStatus
                          this.analysisStatus = true
                          
                      }
                    }
                  },'订单详情'),
                  h('Button',{
                    props: {
                      type:'error',
                      size: "small",
                    },
                    style: {
                      marginRight: '10px',
                      display:params.row.orderStatus ? (params.row.orderStatus == '1' || params.row.orderStatus == '2' ? 'block' :'none') : 'none'
                    },
                    on: {
                      click: () => {
                             this.reasonStatus = true
                             this.reason = ''
                             this.recordId = params.row.recordId
                            //   this.$Modal.confirm({
                            //     title: "温馨提示",
                            //     content:params.row.orderStatus && params.row.orderStatus == '1' ? '"<p>确认取消此处租赁吗</p>"':'"<p>确认取消此次租赁吗？已付款金额会原路返回。</p>"',
                            //     onOk: () => { 
                                    
                            //     }
                            // })
                      }
                    }
                  },'取消订单'),
                  h('Button',{
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      marginRight: '10px',
                      display:params.row.orderStatus ? (params.row.orderStatus == '2' ? 'block' :'none') : 'none'
                    },
                    on: {
                      click: () => {
                              this.$Modal.confirm({
                                title: "温馨提示",
                                content:
                                "<p>确定客户已领取对应辅具？</p>",
                                onOk: () => { 
                                    this.qrCode = params.row.qrCode
                                    this.reciveGood(params.row.qrCode)
                                }
                            })
                      }
                    }
                  },'确认领取'),
                  h('Button',{
                    props: {
                      type: "warning",
                      size: "small",
                    },
                    style: {
                      marginRight: '10px',
                      display:params.row.orderStatus ? (params.row.orderStatus == '3' ? 'block' :'none') : 'none'
                    },
                    on: {
                      click: () => {
                              this.$Modal.confirm({
                                title: "温馨提示",
                                content:
                                "<p>确定客户已归还对应辅具？</p>",
                                onOk: () => { 
                                    this.qrCode = params.row.qrCode
                                    this.backGood(params.row.qrCode)
                                }
                            })
                      }
                    }
                  },'确认归还'),
                ])
              },
                }
          ],
          tableData:[],
          loading:false,
          page:1,
          pageSize:20,
          total:0,
          currentPage:0,
          searchData:{},
          groupType:[
              {label:'全部',value:''},
              {label:'待支付',value:'1'},
              {label:'待领取',value:'2'},
              {label:'待归还',value:'3'},
              {label:'待支付押金',value:'4'},
              {label:'待评价',value:'5'},
              {label:'已完成',value:'6'},
              {label:'已取消',value:'7'},
          ],

          analysisStatus:false,
          showNum:'',
          recordId:'',
          informationId:'',
          orderStatus:'',
          qrCode:'',

          reasonStatus:false,
          reason:''
      }
  },
created(){
    this.getList()
},
methods:{
    backClick(){
      this.$router.push(`/shopindex?menuId=${this.$route.query.menuId}`)
    },
    // 方法部分
    getDetail(){
        this.page = this.currentPage
        this.getList()
    },
    saveReason(){
        if(this.reason == ''){
            this.$Message.warning({
                content:'请输入取消订单原因',
                background:true
            })
            return
        }
        this.cancelGood(this.reason,this.recordId)
    },
    // 原因方法
    reasonStatusFn(status){
        this.reasonStatus = status
    },
    analysisStatusFn(status){
        this.analysisStatus = status
    },
    // 查询按钮
    seatchBtn(){
        this.page = 1
        this.pageSize = 20
        this.getList()
    },
    rest(){
        this.page = 1
        this.pageSize = 20
        this.searchData = {}
        this.getList()
    },
    // 表格分页切换
    hadlePageSize(val){
        this.page = val.page
        this.pageSize = val.pageSize
        this.getList()
    },
    // 接口部分
    // 获取分页接口
    getList(){
        this.loading = true
        this.$get('/old/api/pc/information/goodsLease/selectGoodsLeaseRecordPage',{
            ...this.searchData,
            orgCode:parent.vue.loginInfo.userinfo.orgCode,
            communityCode:'',
            page:this.page,
            pageSize:this.pageSize,
            hasSensitive:this.hasSensitive
        }).then(res=>{
            this.loading = false
            if(res.code == 200){
                this.tableData = res.dataList
                this.total = res.maxCount
                this.currentPage = res.currentPage
            }else{
                this.$Message.error({
                    content:res.desc,
                    background:true
                });
            }
        })
    },
    changeEye(){
      this.hasSensitive = !this.hasSensitive
      this.getList()
    },
    //领取接口
    reciveGood(qrCode){
        this.$post('/old/api/pc/information/goodsLease/receiveGoodsLease',{
            custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
            orgCode:parent.vue.loginInfo.userinfo.orgCode,
            qrCode
        },{"Context-Type":'Application/json'}).then(res=>{
            if(res.code == 200){
                this.$Message.success({
                    content:'领取成功',
                    background:true
                })
                this.page = this.currentPage 
                this.getList()
            }else{
                this.$Message.error({
                    content:'领取失败',
                    background:true
                })
                return
            }
        })
    },
    //归还接口
    backGood(qrCode){
        this.$post('/old/api/pc/information/goodsLease/giveBackGoodsLease',{
            custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
            orgCode:parent.vue.loginInfo.userinfo.orgCode,
            qrCode
        },{"Context-Type":'Application/json'}).then(res=>{
            if(res.code == 200){
                this.$Message.success({
                    content:'归还成功',
                    background:true
                })
                this.page = this.currentPage 
                this.getList()
            }else{
                this.$Message.error({
                    content:'归还失败',
                    background:true
                })
                return
            }
        })
    },
    // 取消订单
    cancelGood(reason,recordId){
        this.$post('/old/api/pc/information/goodsLease/userCancelOrder',{
            reason,
            recordId
        }).then(res=>{
            if(res.code == 200){
                this.$Message.success({
                    content:'取消订单成功',
                    background:true
                })
                this.reasonStatus = false
                this.page = this.currentPage 
                this.getList()
            }else{
                this.$Message.error({
                    content:'取消订单失败',
                    background:true
                })
                return
            }
        })
    } 
}
  
};
</script>
    
<style scoped lang='less'>
.eye{
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin: 0 15px 0 ;
}
</style>