<!--
    orderStatus
   {label:'全部',value:''},
   {label:'待支付',value:'1'},
   {label:'待领取',value:'2'},
   {label:'待归还',value:'3'},
   {label:'待支付押金',value:'4'},
   {label:'待评价',value:'5'},
   {label:'已完成',value:'6'},
   {label:'已取消',value:'7'},
-->

<template>
  <div class="detail-div" v-if="showForm">
      <div class="equiment-title">基本信息</div>
      <div class="base-div" v-if="detailMessage && detailMessage.recordInfo">
         <div class="left">
             <span>订单编码:</span>
             <span class="text">{{detailMessage.recordInfo.orderNo || '未知'}}</span>
             <span class="title">订单状态:</span>
             <span class="text">{{detailMessage.recordInfo.orderStatus == 1 ? '待支付':detailMessage.recordInfo.orderStatus == 2 ? '待领取':detailMessage.recordInfo.orderStatus == 3 ? '待归还' : detailMessage.recordInfo.orderStatus == 4 ? '待支付租金' : detailMessage.recordInfo.orderStatus == 5 ? '待评价': detailMessage.recordInfo.orderStatus == 6 ? '已完成' :detailMessage.recordInfo.orderStatus == 7?'已取消':'' || '暂无'}}</span>

         </div>
         <div class="right">
             <Button type="primary" v-if="detailMessage.recordInfo.orderStatus == 1 || detailMessage.recordInfo.orderStatus == 2" @click="cancelOrder">取消订单</Button>
             <Button type="primary" style="margin-left:10px" v-if="detailMessage.recordInfo.orderStatus == 2" @click="getOrder">确认领取</Button>
             <Button type="primary" style="margin-left:10px" v-if="detailMessage.recordInfo.orderStatus == 3" @click="backOrder">确认归还</Button>
         </div>
      </div>
      <div class="base-div" v-if="detailMessage.formData">
         <div class="left">
             <span>填报人姓名:</span>
             <span class="text">{{detailMessage.formData.createName || '未知'}}</span>
             <span class="title">填报人手机号:</span>
             <span class="text">{{detailMessage.recordInfo.mobile || '暂无'}}</span>
         </div>
         <div class="right">
             <span>已租赁:{{backTime}}</span>
         </div>
      </div>
      <div class="equiment-title">辅具信息</div>
      <div class="equi-div" v-if="detailMessage.information && detailMessage.recordInfo && detailMessage.goodsInfo">
          <div class="left">
              <img class="image" :src="detailMessage.information.photo" alt="">
          </div>
          <div class="right">
              <div class="form-div">
                  <div class="item">
                      <div class="top">商品分类</div>
                      <div class="bottom">{{detailMessage.recordInfo.subTypeName}}</div>
                  </div>
                  <div class="item">
                      <div class="top">商品名称</div>
                      <div class="bottom">{{detailMessage.information.title || '未知'}}</div>
                  </div>
                  <div class="item">
                      <div class="top">商品编码</div>
                      <div class="bottom">{{detailMessage.goodsInfo.goodsNo || ''}}</div>
                  </div>
                  <div class="item">
                      <div class="top">押金</div>
                      <div class="bottom">{{detailMessage.goodsInfo.mortgage || ''}}</div>
                  </div>
                  <div class="item">
                      <div class="top">租金</div>
                      <div class="bottom">{{detailMessage.goodsInfo.leasePrice }}/{{detailMessage.goodsInfo.leaseModule ? (detailMessage.goodsInfo.leaseModule==1?'天':detailMessage.goodsInfo.leaseModule==2?'时':detailMessage.goodsInfo.leaseModule==3?'分钟':detailMessage.goodsInfo.leaseModule==4?'月':detailMessage.goodsInfo.leaseModule==5?'年':'') :''}}</div>
                  </div>
                 
              </div>
          </div>
      </div>
      <div class="equiment-title">表单填写信息</div>
      <div class="container-div" v-if="detailMessage.formData">
          <div class="title">
              <div class="left">类型</div>
              <div class="right">填写</div>
          </div>
          <div class="item" v-for="(item,index) in detailMessage.formData.interactionFormVos" :key="index" >
                   <div class="left">{{item.topic}}</div>
                    <div class="right" v-if="item.formType != 'video' && item.formType != 'image'">{{item.answer}}</div>
                    <div class="right " v-if="item.formType == 'image'">
                        <img class="img" :src="item.answer" v-if="item.answer && item.answer != ''">
                    </div>
                    <div class="right" v-if="item.formType == 'video'">
                        <video controls="controls" class="vide" :src="item.answer" v-if="item.answer && item.answer != ''"></video>
                    </div>
          </div>
      </div>
      <div class="equiment-title">其他信息</div>
      <div class="buy-div" v-if="detailMessage.recordInfo">
          <div class="item">
              <span>下单时间:</span>
              <span class="text">{{detailMessage.recordInfo.gmtModified ? this.$core.formatDate(new Date(detailMessage.recordInfo.gmtModified),"yyyy-MM-dd hh:mm:ss"):'暂无'}}</span>
          </div>
          <div class="item" v-if="detailMessage.recordInfo.orderStatus && (detailMessage.recordInfo.orderStatus == 2 || detailMessage.recordInfo.orderStatus == 3 || detailMessage.recordInfo.orderStatus == 5 || detailMessage.recordInfo.orderStatus == 6 || detailMessage.recordInfo.orderStatus == 7)">
              <span>支付时间:</span>
              <span class="text">{{detailMessage.recordInfo.chargePayTime ? this.$core.formatDate(new Date(detailMessage.recordInfo.chargePayTime),"yyyy-MM-dd hh:mm:ss"):'暂无'}}</span>
          </div>
          <div class="item" v-if="detailMessage.recordInfo.orderStatus && (detailMessage.recordInfo.orderStatus == 3 || detailMessage.recordInfo.orderStatus == 5 || detailMessage.recordInfo.orderStatus == 6 || detailMessage.recordInfo.orderStatus == 7)">
              <span>领取时间:</span>
              <span class="text">{{detailMessage.recordInfo.receiveTime ? this.$core.formatDate(new Date(detailMessage.recordInfo.receiveTime),"yyyy-MM-dd hh:mm:ss"):'暂无'}}</span>
          </div>
          <div class="item" v-if="detailMessage.recordInfo.orderStatus && (detailMessage.recordInfo.orderStatus == 5 || detailMessage.recordInfo.orderStatus == 6)">
              <span>归还时间:</span>
              <span class="text">{{detailMessage.recordInfo.backTime ? this.$core.formatDate(new Date(detailMessage.recordInfo.backTime),"yyyy-MM-dd hh:mm:ss"):'暂无'}}</span>
          </div>
          <div class="item" v-if="detailMessage.recordInfo.orderStatus && (detailMessage.recordInfo.orderStatus == 5 || detailMessage.recordInfo.orderStatus == 6)">
              <span>退款时间:</span>        
              <span class="text">{{detailMessage.recordInfo.mortgageRefundTime ? this.$core.formatDate(new Date(detailMessage.recordInfo.mortgageRefundTime),"yyyy-MM-dd hh:mm:ss"):'暂无'}}</span>
          </div>
          <div class="item" v-if="detailMessage.recordInfo.orderStatus && (detailMessage.recordInfo.orderStatus == 6)">
              <span>评价时间:</span>
              <span class="text">{{detailMessage.recordInfo.evaluationTime ? this.$core.formatDate(new Date(detailMessage.recordInfo.evaluationTime),"yyyy-MM-dd hh:mm:ss"):'暂无'}}</span>
          </div>
      </div>

      <!--取消订单原因-->
        <LiefengModal
        title="取消订单原因"
        width="300px"
        :fullscreen="false"
        :value="reasonStatus"
        @input="reasonStatusFn"
        class="analysis"
      >
        <template v-slot:contentarea>
            <div>
                <Input v-model="reason" placeholder="请输入取消订单原因" />
            </div>
           
        </template>
        <template v-slot:toolsbar>
          <Button
            @click="reasonStatusFn(false)"
            type="info"
            style="margin-right:10px"
            >取消</Button
          >
          <Button
            @click="saveReason"
            type="info"
            >确定</Button
          >
        </template>
      </LiefengModal>
  </div>    
</template>

<script>
import LiefengModal from "@/components/LiefengModal";
export default {
    props:['showNum','recordId','informationId','qrCode'],
    components:{LiefengModal},
    data(){
        return{
            detailMessage:{},
            backTime:'',

            reasonStatus:false,
            reason:'',
            showForm:true


        }
    },
    methods:{
        // 方法部分
        reasonStatusFn(status){
            this.reasonStatus = status
        },
        // 取消订单确认按钮
        saveReason(){
            if(this.reason == ''){
                this.$Message.warning({
                    content:'请输入取消订单原因',
                    background:true
                })
                return
            }
            this.cancelGood(this.reason,this.recordId)
        },
        // 取消订单
        cancelOrder(){
             this.reasonStatus = true
             this.reason = ''
        },
        // 确认领取
        getOrder(){
            this.$Modal.confirm({
             title: "温馨提示",
             content:
             "<p>确定客户已领取对应辅具？</p>",
             onOk: () => { 
                 this.reciveGood(this.qrCode)
             }
         })
        },
        // 确认归还
        backOrder(){
            this.$Modal.confirm({
                title: "温馨提示",
                content:
                "<p>确定客户已归还对应辅具？</p>",
                onOk: () => { 
                    this.backGood(this.qrCode)
                }
            })
        },
        // 接口部分
        getDetail(){
            this.$Message.loading({
                content:'正在加载数据...'
            })
            this.showForm = false
            this.$nextTick(()=>{
                this.showForm = true
            })
            this.detailMessage = {}
            this.backTime = '暂无'
            this.$get('/old/api/pc/information/goodsLease/selectOrderRecordInfoById',{
                recordId:this.recordId,
                informationId:this.informationId,
                orgCode:parent.vue.loginInfo.userinfo.orgCode,
                oemCode:parent.vue.oemInfo.oemCode,
                operatorCustGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                terminal:'2'
            }).then(res=>{
                this.$Message.destroy();
                if(res.code == 200){     
                    this.detailMessage = res.data
                    if(res.data.recordInfo){
                        if(res.data.recordInfo.backTime && res.data.recordInfo.receiveTime){
                            this.backTime = Math.floor((res.data.recordInfo.backTime - res.data.recordInfo.receiveTime)/86400000) + '天'
                        }
                        if(res.data.recordInfo.backTime && (!res.data.recordInfo.receiveTime || res.data.recordInfo.receiveTime == '')){
                            this.backTime = Math.floor((res.data.recordInfo.backTime - new Date().getTime())/ 86400000) + '天'
                        }
                        
                    }
                }else{
                    this.$Message.error({
                        content:'获取详情失败',
                        background:true
                    })
                    return
                }
            })
        },
        //领取接口
        reciveGood(qrCode){
            this.$post('/old/api/pc/information/goodsLease/receiveGoodsLease',{
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode:parent.vue.loginInfo.userinfo.orgCode,
                qrCode
            },{"Context-Type":'Application/json'}).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'领取成功',
                        background:true
                    })
                    this.$emit('getDetail')
                    this.getDetail()
                }else{
                    this.$Message.error({
                        content:'领取失败',
                        background:true
                    })
                    return
                }
            })
        },
        //归还接口
        backGood(qrCode){
            this.$post('/old/api/pc/information/goodsLease/giveBackGoodsLease',{
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode:parent.vue.loginInfo.userinfo.orgCode,
                qrCode
            },{"Context-Type":'Application/json'}).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'归还成功',
                        background:true
                    })
                    this.$emit('getDetail')
                    this.getDetail()
                }else{
                    this.$Message.error({
                        content:'归还失败',
                        background:true
                    })
                    return
                }
            })
        },
         // 取消订单
        cancelGood(reason,recordId){
            this.$post('/old/api/pc/information/goodsLease/userCancelOrder',{
                reason,
                recordId
            }).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'取消订单成功',
                        background:true
                    })
                    this.reasonStatus = false
                    this.$emit('getDetail')
                    this.getDetail()
                }else{
                    this.$Message.error({
                        content:'取消订单失败',
                        background:true
                    })
                    return
                }
            })
        } 
    },
    watch:{
        showNum(){
            this.getDetail()
        }
    }
}
</script>

<style lang="less" scoped>
.detail-div{
    .equiment-title{
        position: relative;
        padding-left: 20px;
        margin:10px 0;
        &::after{
            position: absolute;
            content:'';
            top: 0;
            left: 0;
            width: 5px;
            height: 20px;
            background-color: #2db7f5;
        }
    }
    .base-div{
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        .left{
            .title{
                margin-left: 20px;
            }
            .text{
                margin-left: 10px;
            }
        }
    }
    .equi-div{
        display: flex;
        border: 1px solid #cccccc;
        width: 100%;
        padding: 10px;
        .left{
            width: 20%;
            margin-right: 10px;
            .image{
               border-radius: 5px;
               width: 150px;
               height: 150px; 
             }
        }
        .right{
            width: 80%;
            .form-div{
                display: flex;
                .item{
                    text-align: center;
                    width: 25%;
                    word-wrap: break-word;
                    .top{
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .container-div{
        width: 100%;
        .title{
            height: 40px;
            line-height: 40px;
            display: flex;
            text-align: center;
            font-weight: bold;
            .left{
                width: 50%;
            }
            .right{
                width: 50%;
            }
        }
        .item{
            display: flex;
            text-align: center;
            margin: 10px 0;
            border-top: 1px dashed #cccccc;
            padding: 10px 0;
            .left{
                width: 50%;
            }
            .right{
                width: 50%;
                .img{
                width: 150px;
                height: 150px;
                }
                .vide{
                    width: 150px;
                    height: 150px;
                }
            }
            
        }
    }
    .buy-div{
        .item{
            .text{
                margin-left: 10px;
            }
        }
    }
}
</style>